<template>
  <div>
    <div>
      <div class="container">
        <h4>Create new request</h4>
        <form v-on:submit.prevent="createRequest()">
          <div class="row">
            <div class="col-sm-4">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="sku">Prod. SKU</span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  aria-describedby="sku"
                  v-model="skuRequest"
                  @change="productClient()"
                  required
                />
              </div>
            </div>
            <div class="col">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="name">Prod. Name</span>
                </div>
                <input
                  type="text"
                  aria-describedby="name"
                  class="form-control"
                  v-model="nameRequest"
                  required
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="img">Image Link</span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  aria-describedby="img"
                  v-model="imgRequest"
                  required
                />
              </div>
            </div>
            <div class="col">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="linkProduct"
                    >Prod. Link</span
                  >
                </div>
                <input
                  type="text"
                  class="form-control"
                  aria-describedby="linkProduct"
                  v-model="linkProductRequest"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="quantity"
                    >Prod. Quantity</span
                  >
                </div>
                <input
                  type="number"
                  class="form-control"
                  aria-describedby="quantity"
                  v-model="quantityRequest"
                  required
                />
              </div>
            </div>
            <div class="col">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="price">Prod. Price</span>
                </div>
                <input
                  type="number"
                  class="form-control"
                  aria-describedby="price"
                  v-model="priceRequest"
                />
              </div>
            </div>
            <div class="col">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="price">Type</span>
                </div>
                <select
                  class="form-control"
                  id="exampleFormControlSelect1"
                  v-model="type"
                >
                  <option value="normal">Normal</option>
                  <option value="fba">FBA</option>
                </select>
              </div>
            </div>
            <div class="col-sm-2">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  v-model="urgent"
                />
                <label class="form-check-label" for="flexCheckDefault">
                  Urgent Request
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">Comments</span>
                </div>
                <textarea
                  class="form-control"
                  aria-label="With textarea"
                  v-model="comment"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <button type="submit" class="btn btn-primary float-right">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
      <hr />
      <div class="row" id="search">
        <div class="col-sm-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1"
                >Choose Status:</span
              >
            </div>
            <select
              class="form-control"
              id="exampleFormControlSelect1"
              v-model="status"
            >
              <option value="last_updated">Last Updates</option>
              <option value="pending">Pending</option>
              <option value="offer">Offer</option>
              <option value="counter-offer">Counter Offer</option>
              <option value="canceled">Canceled</option>
              <option value="accepted">Accepted</option>
              <option value="sent">Sent</option>
              <option value="all">All Requests</option>
            </select>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">SKU:</span>
            </div>
            <input id="sku" type="text" class="form-control" v-model="sku" />
          </div>
        </div>
        <div class="col-sm-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1"
                >Prod. Name:</span
              >
            </div>
            <input
              id="sku"
              type="text"
              class="form-control"
              v-model="productName"
            />
          </div>
        </div>
        <div class="col-sm-2">
          <button class="btn btn-info" @click="filterRequests()">Filter</button>
        </div>
      </div>
      <b-table :items="requests" :fields="fields" class="table">
        <template #cell(date)="data">
          <span v-if="data.item.date == null">-</span>
          <span v-else> {{ splitDate(data.item.date) }}</span>
          <br />
          <span v-if="data.item.urgent == true" class="urgent"
            ><b>⚠️ URGENT</b></span
          >
        </template>
        <template #cell(img)="data">
          <span>
            <a :href="data.item.img" type="file" target="_blank"
              ><img
                :src="data.item.img"
                alt="Product Image"
                style="width: 100px; height: 100px"
              />
            </a>
          </span>
        </template>
        <template #cell(our_img)="data">
          <span v-if="data.item.our_img != null">
            <a :href="data.item.our_img" type="file" target="_blank"
              ><img
                :src="data.item.our_img"
                alt="Product Image"
                style="width: 100px; height: 100px"
              />
            </a>
          </span>
          <span v-else>- </span>
        </template>
        <template #cell(status)="data">
          <span v-if="data.item.status == 'pending'" class="pending">
            Pending</span
          ><span v-else-if="data.item.status == 'offer'" class="offer">
            Offer</span
          >
          <span v-else-if="data.item.status == 'canceled'"
            ><span class="canceled">Canceled</span>
            <p class="cancelStatus">{{ data.item.cancel_status }}</p></span
          >
          <span v-else-if="data.item.status == 'accepted'" class="accepted"
            >Accept</span
          >
          <span
            v-else-if="data.item.status == 'counter-offer'"
            class="counter-offer"
          >
            Counter Offer</span
          >
          <span v-else-if="data.item.status == 'sent'" class="sent">Sent</span>
        </template>
        <template #cell(name)="data">
          <span>{{ data.item.name }}</span>
        </template>
        <template #cell(price_offer)="data">
          <span v-if="data.item.price_offer == null">-</span>
          <span v-else>{{ data.item.price_offer }}</span>
        </template>
        <template #cell(price_counteroffer)="data">
          <span v-if="data.item.price_counteroffer == null">-</span>
          <span v-else>{{ data.item.price_counteroffer }}</span>
        </template>
        <template #cell(price)="data">
          <span v-if="data.item.price == null">-</span>
          <span v-else>{{ data.item.price }}</span>
        </template>
        <template #cell(comments)="data">
          <div v-if="checkHowManyMsgs(data) != 0">
            <button
              class="btn btn-info"
              id="notification"
              @click="openMsg(data)"
            >
              💬
              <span class="badge">{{ checkHowManyMsgs(data) }}</span>
            </button>
          </div>
          <div v-else>
            <button class="btn btn-info" @click="openMsg(data)">💬</button>
          </div>
        </template>
        <template #cell(cancel)="data">
          <span
            v-if="data.item.status != 'accepted' && data.item.status != 'sent'"
          >
            <button class="btn btn-primary btn-sm" @click="openModalEdit(data)">
              Edit
            </button>
          </span>
          <span v-if="data.item.status == 'pending'"
            ><button
              class="btn btn-danger btn-sm"
              @click="cancelRequest(data.item.id)"
            >
              Cancel
            </button></span
          >
          <span v-else-if="data.item.status == 'offer'">
            <button
              id="btn-offer"
              class="btn btn-success btn-sm"
              @click="acceptOffer(data)"
            >
              Accept
            </button>
            <button
              id="btn-offer"
              class="btn btn-info btn-sm"
              v-b-modal.modal-counteroffer
              @click="modalCounterOffer(data)"
            >
              CounterOffer
            </button>
            <button
              id="btn-offer"
              class="btn btn-danger btn-sm"
              @click="cancelRequest(data.item.id)"
            >
              Refuse
            </button></span
          >
          <span
            v-if="
              data.item.status == 'accepted' && data.item.finance_sheet != true
            "
          >
            <button
              class="btn btn-primary btn-sm"
              @click="financialSheet(data.item.id)"
            >
              Check Finance Sheet
            </button>
          </span>
          <span v-else-if="data.item.finance_sheet == true"
            ><b>Finance Sheet Checked</b></span
          >
          <button
            class="btn btn-primary btn-sm"
            @click="clientLogs(data.item.id)"
          >
            Logs
          </button>
        </template>
        <template #cell(qty)="data">
          <p>
            <span> <b>Request: </b> {{ data.item.qty }} </span>
          </p>
          <p>
            <b>Conf: </b>
            <span v-if="data.item.qty_accepted == null">-</span>
            <span v-else>{{ data.item.qty_accepted }}</span>
          </p>
          <p>
            <b>Sent: </b><span v-if="data.item.qty_sent == null">-</span>
            <span v-else>{{ data.item.qty_sent }}</span>
          </p>
        </template>
      </b-table>
    </div>
    <b-modal
      id="modal-counteroffer"
      ref="modal-counteroffer"
      title="Counter Offer"
      hide-footer
    >
      <p><b>Name: </b> {{ modalName }}</p>
      <p><b>SKU's: </b>{{ modalSku }}</p>
      <p><b>Quantity: </b>{{ modalQuantity }}</p>
      <p><b>Price Sale: </b>{{ modalPrice }}</p>
      <p><b>Price Offer: </b>{{ modalPriceOffer }}</p>
      <form v-on:submit.prevent="counterOffer()">
        <div class="form-group">
          <label for="price_offer"><b>Counter Offer:</b></label>
          <input
            type="number"
            class="form-control"
            id="counter_offer"
            v-model="counter_offer"
          />
        </div>
        <button class="btn btn-primary">Make Counter Offer</button>
      </form>
    </b-modal>
    <b-modal id="modal-msg" ref="modal-msg" title="Comments" hide-footer>
      <span v-for="msg of msgs" :key="msg">
        <span v-if="msg.user == userEmail || msg.user == username">
          <div class="card" id="msg" style="border-color: blue">
            <div class="card-body" style="text-align: right">
              <b>{{ msg.user }}:</b> {{ msg.comment }}
            </div>
          </div></span
        >
        <span v-else>
          <div class="card" id="msg">
            <div class="card-body" style="text-align: left">
              <b>{{ msg.user }}:</b> {{ msg.comment }}
            </div>
          </div></span
        ></span
      >
      <hr />
      <span>New Message:</span>
      <textarea
        class="form-control"
        aria-label="With textarea"
        v-model="newMsg"
      ></textarea>
      <button class="btn btn-info float-right" @click="sendComment()">
        Send
      </button>
    </b-modal>
    <b-modal id="modal-edit" ref="modal-edit" title="Edit Request" hide-footer>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="sku">Prod. SKU</span>
        </div>
        <input
          type="text"
          class="form-control"
          aria-describedby="sku"
          v-model="editSku"
          required
        />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="name">Name</span>
        </div>
        <input
          type="text"
          class="form-control"
          aria-describedby="sku"
          v-model="editName"
          required
        />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="name">Product Link</span>
        </div>
        <input
          type="text"
          class="form-control"
          aria-describedby="sku"
          v-model="editLink"
        />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="name">Price Sale</span>
        </div>
        <input
          type="number"
          class="form-control"
          aria-describedby="sku"
          v-model="editPriceSale"
          required
        />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="name">Quantity</span>
        </div>
        <input
          type="number"
          class="form-control"
          aria-describedby="sku"
          v-model="editQty"
          required
        />
      </div>
      <div v-if="editStatus == 'counter-offer'" class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="name">Counter Offer</span>
        </div>
        <input
          type="number"
          class="form-control"
          aria-describedby="sku"
          v-model="editCounterOffer"
          required
        />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="name">Type</span>
        </div>
        <select
          class="form-control"
          id="exampleFormControlSelect1"
          v-model="editType"
        >
          <option value="normal">Normal</option>
          <option value="fba">FBA</option>
        </select>
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="name">Image Link</span>
        </div>
        <input
          type="text"
          class="form-control"
          aria-describedby="sku"
          v-model="editImage"
        />
      </div>
      <img
        :src="editImage"
        alt=""
        style="width: 300px; height: 300px; margin-left: 15%"
      />
      <div>
        <button class="btn btn-primary" @click="editRequest()">Edit</button>
      </div>
    </b-modal>
    <b-modal
      id="modal-logs"
      size="lg"
      ref="modal-logs"
      title="Request Logs"
      hide-footer
    >
      <table class="table">
        <tr>
          <th>Date</th>
          <th>Description</th>
          <th>User</th>
        </tr>
        <tr v-for="log in logs" :key="log.id">
          <td>{{ log.date }}</td>
          <td>{{ checkTask(log.task) }}</td>
          <td>{{ log.username }}</td>
        </tr>
      </table>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data: function () {
    return {
      urgent: false,
      skuRequest: '',
      nameRequest: '',
      quantityRequest: 0,
      priceRequest: 0,
      imgRequest: '',
      linkProductRequest: '',
      id_backoffice: '',
      modalid: 0,
      modalName: '',
      modalPrice: 0,
      modalQuantity: 0,
      modalImg: '',
      modalSku: '',
      modalPriceOffer: 0,
      counter_offer: 0,
      status: 'last_updated',
      sku: '',
      comment: '',
      type: 'normal',
      requests: [],
      msgs: [],
      userEmail: '',
      newMsg: '',
      editSku: '',
      editName: '',
      editImage: '',
      editLink: '',
      editPriceSale: 0,
      editType: '',
      editQty: 0,
      editId: 0,
      editCounterOffer: 0,
      editOffer: 0,
      editStatus: '',
      username: '',
      productName: '',
      logs: [],
      trackingMovements: [],
      product: [],
      fields: [
        {
          key: 'id',
          label: 'ID',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'date',
          label: 'Date',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Status',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'img',
          label: 'Image',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147); vertical-align: middle',
        },
        {
          key: 'our_img',
          label: 'Idivia Img',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147); vertical-align: middle',
        },
        {
          key: 'sku',
          label: 'SKU',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Prod. Name',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'qty',
          label: 'Quantity',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'price_sale',
          label: 'Price Sale',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'price_offer',
          label: 'Price Offer',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'price_counteroffer',
          label: 'Price Counter Offer',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'price',
          label: 'Price Accepted',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'type',
          label: 'Type',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147); vertical-align: middle',
        },
        {
          key: 'comments',
          label: 'Comments',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147); vertical-align: middle',
        },
        {
          key: 'cancel',
          label: 'Events',
          tdClass: 'align-middle',
          thStyle:
            'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
      ],
    };
  },
  methods: {
    createRequest: async function () {
      // Se a quantidade for menor que 1, exibe alerta e não prossegue
      if (this.quantityRequest < 1) {
        return this.$swal('Error', 'Quantity must be at least 1', 'error');
      }

      // Aqui, segue o fluxo normal de confirmação
      this.$swal({
        title: 'Confirm the request?',
        text: `SKU: ${this.skuRequest} \n Name: ${this.nameRequest} \n Quantity: ${this.quantityRequest} \n Price: ${this.priceRequest}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#5a4ec7',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch('addClientRequest', {
              sku: this.skuRequest,
              name: this.nameRequest,
              img: this.imgRequest,
              qty: this.quantityRequest,
              price_sale: this.priceRequest,
              product_link: this.linkProductRequest,
              status: 'pending',
              type: this.type,
              comments: this.comment,
              id_backoffice: this.id_backoffice,
              urgent: this.urgent,
            });
            this.$swal('Request created!', '', 'success');

            // Reset dos campos
            this.skuRequest = '';
            this.nameRequest = '';
            this.quantityRequest = 0;
            this.priceRequest = 0;
            this.imgRequest = '';
            this.linkProductRequest = '';
            this.type = 'normal';
            this.comment = '';
            this.urgent = false;

            // Atualiza a lista
            this.lastUpdateRequest();
          } catch (error) {
            alert(error);
            console.log(error);
          }
        }
      });
    },
    async getRequests() {
      try {
        await this.$store.dispatch('getClientRequests');
        console.log(this.getRequestsClient);
        this.requests = this.getRequestsClient;
      } catch (error) {
        console.log(error);
      }
    },
    async modalCounterOffer(data) {
      this.modalid = data.item.id;
      this.modalName = data.item.our_name;
      this.modalPrice = data.item.price_sale;
      this.modalQuantity = data.item.qty;
      this.modalImg = data.item.our_img;
      this.modalSku = data.item.sku;
      this.modalPriceOffer = data.item.price_offer;
    },
    async counterOffer() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#5a4ec7',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, make counter offer!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch('updateClientCounterOffer', {
              id: this.modalid,
              status: 'counter-offer',
              counter_offer: this.counter_offer,
            });
            this.$refs['modal-counteroffer'].hide();
            this.lastUpdateRequest();
          } catch (error) {
            alert(error);
          }
          this.$swal(
            'Counter Offer!',
            'Your counter offer has been made.',
            'success'
          );
        }
      });
    },
    async cancelRequest(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#5a4ec7',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, cancel request!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch('cancelClientRequest', {
              id: id,
              status: 'canceled',
            });
            this.lastUpdateRequest();
            this.$swal(
              'Request Canceled!',
              'Your request has been canceled.',
              'success'
            );
          } catch (error) {
            alert(error);
            console.log(error);
          }
        }
      });
    },
    splitDate(date) {
      let split = date.split('T');
      return split[0];
    },
    async filterRequests() {
      try {
        await this.$store.dispatch('filterClientRequest', {
          status: this.status,
          sku: this.sku,
          name: this.productName,
        });
        this.requests = this.getFilterRequestsClient;
        console.log(this.requests);
      } catch (error) {
        alert(error);
        console.log(error);
      }
    },
    async acceptOffer(data) {
      this.$swal({
        title: `The value of the product will be ${data.item.price_offer}!`,
        text: 'You will not be able to reverse this action!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#5a4ec7',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, accept offer!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch('acceptRequest', {
              id: data.item.id,
              status: 'accepted',
              price_final: data.item.price_offer,
              email: data.item.email,
            });
            this.$swal({
              title: 'Success!',
              text: 'Offer successfully accepted!',
              icon: 'success',
              button: 'Ok',
            });
            this.lastUpdateRequest();
          } catch (error) {
            alert(error);
            console.log(error);
          }
        }
      });
    },
    async openMsg(data) {
      this.modalid = data.item.id;
      this.msgs = JSON.parse(data.item.comments);
      this.$refs['modal-msg'].show();
    },
    async lastUpdateRequest() {
      try {
        await this.$store.dispatch('lastUpdatedClientRequest');
        console.log(this.getlastClientRequest);
        this.requests = this.getlastClientRequest;
      } catch (error) {
        alert(error);
        console.log(error);
      }
    },
    async getUser() {
      let user = localStorage.getItem('user');
      this.userEmail = JSON.parse(user).email;
      this.username = JSON.parse(user).username;
    },
    async sendComment() {
      try {
        await this.$store.dispatch('addCommentToRequest', {
          id: this.modalid,
          comment: this.newMsg,
          oldComments: this.msgs,
        });
        await this.lastUpdateRequest();
        await this.getmsgs(this.modalid);
        this.newMsg = '';
      } catch (error) {
        alert(error);
        console.log(error);
      }
    },
    async getmsgs(id) {
      this.requests.filter((item) => {
        if (item.id == id) {
          this.msgs = JSON.parse(item.comments);
        }
      });
    },
    async openModalEdit(data) {
      this.$refs['modal-edit'].show();
      this.editId = data.item.id;
      this.editName = data.item.name;
      this.editPriceSale = data.item.price_sale;
      this.editImage = data.item.img;
      this.editSku = data.item.sku;
      this.editQty = data.item.qty;
      this.editType = data.item.type;
      this.editLink = data.item.link;
      this.editCounterOffer = data.item.price_counteroffer;
      this.editOffer = data.item.price_offer;
      this.editStatus = data.item.status;
    },
    async editRequest() {
      try {
        await this.$store.dispatch('editRequestClient', {
          id: this.editId,
          img: this.editImage,
          name: this.editName,
          sku: this.editSku,
          price_sale: this.editPriceSale,
          product_link: this.editLink,
          type: this.editType,
          qty: this.editQty,
          counterOffer: this.editCounterOffer,
          priceOffer: this.editOffer,
        });
        this.$swal({
          title: 'Success!',
          text: 'Request successfully edited!',
          icon: 'success',
          button: 'Ok',
        });
        this.$refs['modal-edit'].hide();
        this.lastUpdateRequest();
      } catch (error) {
        alert(error);
        console.log(error);
      }
    },
    checkHowManyMsgs(data) {
      let msgs = JSON.parse(data.item.comments);
      let totalNewMsgs = 0;
      for (let i = msgs.length - 1; i >= 0; i--) {
        if (msgs[i].user != this.userEmail && msgs[i].user != this.username) {
          totalNewMsgs++;
        } else {
          break;
        }
      }
      return totalNewMsgs;
    },
    async productClient() {
      this.skuRequest = this.skuRequest.replace(/\s/g, '');
      this.skuRequest = this.skuRequest.replace(/\./g, '');
      try {
        await this.$store.dispatch('getClientProduct', {
          sku: this.skuRequest,
        });
        this.product = this.getProductClient;
        console.log(this.product);
        if (this.product.length != 0) {
          this.nameRequest = this.product[0].name;
          this.imgRequest = this.product[0].img;
          this.linkProductRequest = this.product[0].product_link;
          this.priceRequest = this.product[0].price_sale;
          this.id_backoffice = this.product[0].id_backoffice;
        } else {
          this.nameRequest = '';
          this.imgRequest = '';
          this.linkProductRequest = '';
          this.priceRequest = 0;
          this.id_backoffice = '';
        }
      } catch (error) {
        alert(error);
        console.log(error);
      }
    },
    async financialSheet(id) {
      this.$swal({
        title: 'Confirm?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#5a4ec7',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch('financialSheet', {
              id: id,
              finance_sheet: true,
            });
            this.$swal('Checked!', '', 'success');
            this.lastUpdateRequest();
          } catch (error) {
            alert(error);
            console.log(error);
          }
        }
      });
    },
    async clientLogs(id) {
      try {
        await this.$store.dispatch('logsClientRequest', {
          id: id,
        });
        this.logs = this.getLogs;
        console.log(this.logs);
        this.$refs['modal-logs'].show();
      } catch (error) {
        alert(error);
        console.log(error);
      }
    },
    checkTask(task) {
      if (task.includes('accepted')) {
        return 'Offer Accepted';
      } else if (task.includes('counter-offer')) {
        return 'Counter Offer';
      } else if (task.includes('canceled')) {
        return 'Request Canceled';
      }
      console.log(task);
      return task;
    },
  },
  computed: {
    ...mapGetters(['getRequestsClient']),
    ...mapGetters(['getFilterRequestsClient']),
    ...mapGetters(['getlastClientRequest']),
    ...mapGetters(['getProductClient']),
    ...mapGetters(['getLogs']),
  },
  created() {
    this.lastUpdateRequest();
    this.getUser();
    // this.getRequests()
  },
};
</script>
<style scoped>
.table {
  text-align: center;
}
.pending {
  background-color: rgb(26, 140, 196);
  padding: 6px;
  border-radius: 8px;
  color: white;
}
.offer {
  background-color: #5a4ec7;
  padding: 6px;
  border-radius: 8px;
  color: white;
  width: 100%;
}
.counter-offer {
  background-color: #ffaf02;
  padding: 6px;
  border-radius: 8px;
  color: white;
  width: 100%;
}
.canceled {
  background-color: #b5182a;
  padding: 6px;
  border-radius: 8px;
  color: white;
  width: 100%;
}
.accepted {
  background-color: #538102;
  padding: 6px;
  border-radius: 8px;
  color: white;
  width: 100%;
}
.sent {
  background-color: purple;
  padding: 6px;
  border-radius: 8px;
  color: white;
  width: 100%;
}
#btn-offer {
  margin: 2px;
}
#search {
  margin: 20px;
}
textarea {
  resize: none;
}
#msg {
  margin: 5px;
}
#notification {
  padding-right: 0px;
}
#notification .badge {
  color: red;
  top: -17px;
  right: -6px;
  border-radius: 50%;
  background: red;
  color: white;
}
.cancelStatus {
  margin-top: 10px;
  text-decoration: underline;
  font-size: 12px;
}
.urgent {
  color: red;
}
</style>
